import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/app/app/swr-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/ThemeProviderWithMode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/containers/NotificationSnackbar/NotificationSnackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppContextProvider"] */ "/app/context/AppContext/AppContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationsContextProvider"] */ "/app/context/NotificationsContext/NotificationsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/app/context/UserContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/layouts/MainLayout/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/layouts/MainLayout/SideNav/SideNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/layouts/MainLayout/TopNav.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/CssBaseline/CssBaseline.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Typography/Typography.js");
