"use client";

import { useAppContext } from "@/context/AppContext/AppContext";
import useUser from "@/context/UserContext";
import { BottomIcons, SideNavItem } from "@/layouts/MainLayout/SideNav/SideNav.components";
import {
    sideNavItemsDataAdmin,
    sideNavItemsDataUsers,
    sideNavWidthPx,
} from "@/layouts/MainLayout/SideNav/SideNav.data";
import version from "@/version";
import { Box, Divider, Drawer, List, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Image from "next/image";
import { usePathname } from "next/navigation";

const SideNav = () => {
    const appContext = useAppContext();
    const {
        userInfo: { isLoggedIn, user },
        preferences: {
            preferences: { darkMode },
        },
    } = useUser();
    const pathname = usePathname();

    const SideNavListItemsUsers = () =>
        sideNavItemsDataUsers.map(s => (
            <SideNavItem
                key={s.title}
                title={s.title}
                href={s.href}
                disabled={!isLoggedIn}
                Icon={s.Icon}
                selected={s.href === "/" ? pathname === s.href : pathname.includes(s.href)}
            />
        ));

    const SideNavListItemsAdmin = () =>
        user?.role === "Admin" &&
        sideNavItemsDataAdmin.map(s => (
            <SideNavItem
                key={s.title}
                title={s.title}
                href={s.href}
                disabled={!isLoggedIn || user?.role !== "Admin"}
                Icon={s.Icon}
                selected={s.href === "/" ? pathname === s.href : pathname.includes(s.href)}
            />
        ));

    const LogoLightMode = () => (
        <Image src={require("../../../public/logo-without-by.png")} alt="Capsa Logo" width={100} height={40} />
    );

    const LogoDarkMode = () => (
        <Image src={require("../../../public/logo-without-by-darkmode.png")} alt="Capsa Logo" width={100} height={40} />
    );

    const WelcomeText = () => (user ? <>Welcome, {user?.firstName}</> : <>Not logged in</>);

    return (
        <Drawer variant="permanent" open={true}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100vh",
                    width: sideNavWidthPx,
                }}
            >
                <List sx={{ width: sideNavWidthPx }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "-8px", // Remove top margin from List
                            marginBottom: "24px",
                            padding: "16px",
                            width: "100%",
                            backgroundColor: "rgb(72,55,0, 0.06)",
                            // For dark mode
                            ...(darkMode && {
                                backgroundColor: "rgba(255,222,190,0.03)",
                            }),
                        }}
                    >
                        {darkMode ? <LogoDarkMode /> : <LogoLightMode />}
                    </Box>
                    <SideNavListItemsUsers />
                </List>
                <List sx={{ width: sideNavWidthPx }}>
                    <SideNavListItemsAdmin />
                    <Divider sx={{ mt: 1, mb: 3 }} />
                    <Tooltip
                        title={`User ID: ${user?.userUUID ?? "loading..."} | Role: ${user?.role ?? "loading..."}`}
                        placement="top"
                        suppressHydrationWarning
                    >
                        <Typography
                            component="p"
                            color={grey[400]}
                            variant="caption"
                            align="center"
                            marginBottom={1}
                            sx={{ width: "100%" }}
                        >
                            <WelcomeText />
                        </Typography>
                    </Tooltip>
                    <Tooltip
                        title={`Capsa API Server: ${appContext.env?.serverUrl ?? "Loading endpoint..."}`}
                        placement="top"
                        suppressHydrationWarning
                    >
                        <Typography
                            component="p"
                            color={grey[400]}
                            variant="caption"
                            align="center"
                            marginBottom={1}
                            sx={{ width: "100%" }}
                        >
                            Capsa v{version}
                        </Typography>
                    </Tooltip>
                    <BottomIcons />
                </List>
            </Box>
        </Drawer>
    );
};

export default SideNav;
